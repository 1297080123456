<template>
    <div class="container-action-button" v-if="params.data">
        <BaseButton v-if="params.data.affairTasks?.length > 0" class="blue-button rectangle-button" @click="onConsultAffairDetail" buttonText="Détail">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'search']" />
            </template>
        </BaseButton>
    </div>
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton.vue';
    import * as Vue from "vue";
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    export default {
        name: 'ListAffairsActionButtonsRenderer',
        components: {
            BaseButton: Vue.shallowRef(BaseButton),
            FontAwesomeIcon: Vue.shallowRef(FontAwesomeIcon)
        },
        methods: {
            onConsultAffairDetail() {
                this.params.context.componentParent.displayConsultAffairDetailModal(this.params.data);
            }
        }
    }        
</script>