<style scoped>

.topbar-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
  position: fixed;
  right: 30px;
  top: 16px;
  z-index: 22;
}

.topbar-buttons-container .topbar-right-button {
  position: initial !important;
}

#main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#main-container > div:not(last-child) {
  margin-bottom: 30px;
}

.modal-clocking-task-container {
  display: flex;
  overflow: hidden;
}

.modal-clocking-task-container .task-is-closed {
  margin: 10px;
}

.left-side {
  width: 30%;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgb(220, 225, 230);
}

.right-side {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.discipline-container-title {
  background-color: #0d3b70;
  color: white;
  height: 32px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  position: relative;
  margin-top: 20px;
}

.radio-list {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  margin: 15px 0 20px 0;
}

.radio-button-container {
  flex-basis: 50% !important;
}

.discipline-container-title:after {
  position: absolute;
  right: -20px;
  top: 0;
  content: '';
  width: 0;
  height: 0;
  border-top: 0 solid transparent;
  border-bottom: 32px solid transparent;
  border-left: 20px solid #0d3b70;
}

#modal-affair-detail #modal-affair-detail-title {
  font-size: 24px;
  color: rgb(50, 60, 70);
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 600;
  text-align: center;
}

#modal-affair-detail #modal-affair-detail-subtitle {
  font-size: 14px;
  color: rgb(50, 60, 70);
  margin-bottom: 5px;
  font-weight: 500;
  text-align: center;
}

#modal-affair-detail #modal-affair-detail-subtitle-2 {
  font-size: 11px;
  color: rgb(50, 60, 70);
  margin-bottom: 20px;
  text-align: center;
}

#modal-affair-detail table {
  text-align: left;
  border-collapse: collapse;
  width: calc(100% - 40px);
  margin: 0 20px 40px 20px;
}

#modal-affair-detail table thead {
  background-color: rgb(240, 243, 246);
  color: rgb(100, 110, 120);
  font-size: 11px;
  text-transform: uppercase;
}

#modal-affair-detail table thead th {
  font-weight: 500;
  padding: 13px 20px;
  white-space: nowrap;
}

#modal-affair-detail table tbody tr {
  border-bottom: 1px solid rgb(230, 235, 240);
}

#modal-affair-detail table tbody td {
  padding: 15px 20px;
  font-weight: 500;
  font-size: 13px;
  color: rgb(30, 40, 50);
}

#modal-affair-detail .button {
  margin: 0px !important;
}

:deep(.aggrid-filter .multiselect) {
  width: 240px;
}

:deep(aggrid-filter .flatpickr-input) {
  height: 40px;
}
</style>

<template>
  <div class="topbar-buttons-container">
    <ButtonTopbar buttonText="Nouvelle tâche" @onTopbarButtonClicked="displayCreateTaskModal">
      <template v-slot:iconBefore>
        <font-awesome-icon :icon="['fas', 'plus']"/>
      </template>
    </ButtonTopbar>
    <ButtonTopbar buttonText="Générateur d'état" @onTopbarButtonClicked="displayStateGeneratorModal">
      <template v-slot:iconBefore>
        <font-awesome-icon :icon="['fas', 'file-excel']"/>
      </template>
    </ButtonTopbar>
  </div>

  <div id="main-container">
    <div>
      <Aggrid
          :columnDefs="columnDefsTasks"
          apiRoute="clockingTask/list"
          :apiParams="filterTasks"
          :frameworkComponents="frameworkComponentsTasks"
          :context="context"
          title="TÂCHES EN COURS"
          aggridHeightSubstract="51vh"
      >
        <template v-slot:filter>
          <BaseCheckbox label="Afficher les affaires terminées" @onChange="onDisplayOldAffairsFilterChange"/>
          <div>
            <BaseSelect
                v-model="technicianSearch"
                label="Agent"
                name="accountId"
                @onChange="onFiltersChange($event)"
                fieldValue="id"
                fieldLabel="name"
                :searchable="true"
                api="account/search"
                :apiParams="{
                                role: [accountRoleEnum.TECHNICIAN, accountRoleEnum.WORKSHOP_MANAGER, accountRoleEnum.TECHNICIAN_MANAGER]
                            }"
                v-if="!$store.getters.isTechnician"
                :minChars="1"
                :maxHeight="300"
            />
          </div>
          <div>
            <BaseSelect
                v-model="affairSearch"
                label="Affaire"
                name="affairId"
                @onChange="onFiltersChange($event)"
                fieldValue="id"
                fieldLabel="name"
                :searchable="true"
                api="affair/search"
                :minChars="2"
                :maxHeight="300"
            />
          </div>
          <div>
            <BaseSelect
                v-model="spindleBrandSearch"
                label="Marque de broche"
                name="spindleBrandId"
                fieldValue="id"
                fieldLabel="name"
                @onChange="onFiltersChange($event)"
                :searchable="true"
                api="spindleBrand/search"
                v-if="!$store.getters.isTechnician"
                :maxHeight="300"
            />
          </div>
          <div>
            <BaseSelect
                v-model="spindleTypeSearch"
                label="Type de broche"
                name="spindleTypeId"
                fieldValue="id"
                fieldLabel="name"
                @onChange="onFiltersChange($event)"
                :searchable="true"
                api="spindleType/search"
                v-if="!$store.getters.isTechnician"
                :maxHeight="300"
            />
          </div>
          <div>
            <BaseDatepicker
                v-model="dateSearch"
                name="dateSearch"
                label="Date du pointage"
                @onChange="onFiltersChange($event)"
                :autoClose="false"
            />
          </div>
        </template>
      </Aggrid>
    </div>
    <div>
      <Aggrid
          :columnDefs="columnDefsAffairs"
          apiRoute="affair/list"
          :apiParams="filterAffairs"
          :frameworkComponents="frameworkComponentsAffairs"
          :context="context"
          title="TEMPS TOTAL PASSÉ SUR CHAQUE AFFAIRE"
          aggridHeightSubstract="50vh"
      />
    </div>
    <div v-if="filterPreSearch.accountId !== null && filterPreSearch.dateSearch !== null">
      <Aggrid
          :columnDefs="columnDefsPreSearch"
          apiRoute="clockingTask/preSearch"
          :apiParams="filterPreSearch"
          :context="contextPreSearch"
          title="RESULTAT DE RECHERCHE"
      />
    </div>
  </div>

  <Modal id="modal-affair-detail" :fullscreen="true" :hideTitle="true" :hideIcon="true"
         v-show="displayModalConsultAffairDetail === true">
    <template v-slot:modalContent>
      <div id="modal-affair-detail-title">{{ affair.name }}</div>
      <div id="modal-affair-detail-subtitle">{{ affair.spindleBrandName }} {{ affair.spindleTypeName }}</div>
      <div id="modal-affair-detail-subtitle-2">Durée totale : {{ affair.duration || '0H00' }}</div>
      <table>
        <thead>
        <tr>
          <th>Agent</th>
          <th>Discipline</th>
          <th v-on:click="() => changeClockingTaskFromAffairDetailFilter(2)">Date de début &nbsp;<span
              v-if="clockingTaskFromAffairDetailFilter && (clockingTaskFromAffairDetailFilter?.columnIndex === 2)"><font-awesome-icon
              :icon="['fas', clockingTaskFromAffairDetailFilter.sorting === 'asc' ? 'arrow-up' : 'arrow-down']"/></span>
          </th>
          <th v-on:click="() => changeClockingTaskFromAffairDetailFilter(3)">Date de fin &nbsp;<span
              v-if="clockingTaskFromAffairDetailFilter && (clockingTaskFromAffairDetailFilter?.columnIndex === 3)"><font-awesome-icon
              :icon="['fas', clockingTaskFromAffairDetailFilter.sorting === 'asc' ? 'arrow-up' : 'arrow-down']"/></span>
          </th>
          <th v-on:click="() => changeClockingTaskFromAffairDetailFilter(4)">Temps total de pointage &nbsp;<span
              v-if="clockingTaskFromAffairDetailFilter && (clockingTaskFromAffairDetailFilter?.columnIndex === 4)"><font-awesome-icon
              :icon="['fas', clockingTaskFromAffairDetailFilter.sorting === 'asc' ? 'arrow-up' : 'arrow-down']"/></span>
          </th>
          <th v-if="!$store.getters.isTechnician"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(task, index) in affair.tasks" :key="index">
          <td>{{ task.account.name }}</td>
          <td>{{ task.discipline.description }}</td>
          <td>{{ task.startedDate }}</td>
          <td>{{ task.endedDate }}</td>
          <td>{{ task.duration }}</td>
          <td v-if="!$store.getters.isTechnician">
            <div style="display: flex; flex-direction: row;gap:8px;">
              <BaseButton :data-index="index" class="blue-button" @click="editClockingTaskFromAffairDetail"
                buttonText="Modifier">
                <template v-slot:iconBefore>
                  <font-awesome-icon :icon="['fas', 'pen']"/>
                </template>
              </BaseButton>
              <BaseButton :data-index="index" class="orange-button" @click="deleteClockingTaskFromAffairDetail"
                          buttonText="Supprimer">
                <template v-slot:iconBefore>
                  <font-awesome-icon :icon="['fas', 'trash']"/>
                </template>
              </BaseButton>
            </div>

          </td>
        </tr>
        </tbody>
      </table>
    </template>
    <template v-slot:modalButtons>
      <BaseButton class="white-button" @click="closeModalAffairDetail" buttonText="Fermer">
        <template v-slot:iconBefore>
          <font-awesome-icon :icon="['fas', 'times']"/>
        </template>
      </BaseButton>
    </template>
  </Modal>

  <Modal :hideTitle="true" :hideIcon="true" v-show="displayModalCreateOrEditClockingTask === true">
    <template v-slot:modalContent>
      <div class="modal-clocking-task-container">
        <div class="left-side">
          <BaseSelect
              v-model="task.account"
              label="Agent"
              name="taskAccount"
              @onChange="onFormInputChange($event)"
              :error="formErrors?.taskAccount?.error?.message"
              :displayError="displayError"
              :apiParams="{
                            role: [accountRoleEnum.TECHNICIAN, accountRoleEnum.WORKSHOP_MANAGER, accountRoleEnum.TECHNICIAN_MANAGER]
                        }"
              fieldValue="id"
              fieldLabel="name"
              :required="true"
              :searchable="true"
              api="account/search"
              v-if="!$store.getters.isTechnician"
              :minChars="1"
          />
          <BaseSelect
              v-model="task.affair"
              label="Affaire"
              name="taskAffair"
              @onChange="onFormInputChange($event)"
              :error="formErrors?.taskAffair?.error?.message"
              :displayError="displayError"
              fieldValue="id"
              fieldLabel="name"
              :required="true"
              :searchable="true"
              api="affair/search"
              :apiParams="{
                onlyClockingTaskable: true
              }"
              :minChars="2"
          />
          <BaseDatepicker
              v-model="task.startDate"
              label="Date de début du pointage"
              name="taskStartDate"
              :required="true"
              :error="formErrors?.taskStartDate?.error?.message"
              @onChange="onFormInputChange"
              :displayError="displayError"
              :displayTime="true"
              :autoClose="false"
          />
          <BaseDatepicker
              v-model="task.endDate"
              label="Date de fin du pointage"
              name="taskEndDate"
              :required="true"
              :error="formErrors?.taskEndDate?.error?.message"
              @onChange="onFormInputChange"
              :displayError="displayError"
              :displayTime="true"
              :autoClose="false"
          />
          <BaseCheckbox
              v-model="task.isClosed"
              class="task-is-closed"
              label="Cette tâche est terminée"
          />
          <BaseInput
              v-model="task.comment"
              label="Commentaire"
              type="textarea"
              name="taskComment"
              rows="15"
          />
        </div>
        <div class="right-side">
          <div class="discipline-container-title" v-if="affairDisciplines.length > 0">Disciplines récurrentes pour cette
            broche
          </div>
          <div class="radio-list" v-if="affairDisciplines.length > 0">
            <BaseRadioButton v-for="(item) in affairDisciplines" :key="item.id" v-model="task.discipline"
                             :label="item.label" name="taskDiscipline" :value="item.id"></BaseRadioButton>
          </div>
          <div class="discipline-container-title">Autres disciplines</div>
          <div class="radio-list">
            <BaseRadioButton v-for="(item) in othersDisciplines" :key="item.id" v-model="task.discipline"
                             :label="item.label" name="taskDiscipline" :value="item.id"></BaseRadioButton>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:modalButtons>
      <BaseButton class="white-button" @click="closeModalClockingTask" buttonText="Fermer">
        <template v-slot:iconBefore>
          <font-awesome-icon :icon="['fas', 'times']"/>
        </template>
      </BaseButton>
      <BaseButton class="orange-button" @click="submitModalClockingTask" buttonText="Valider">
        <template v-slot:iconBefore>
          <font-awesome-icon :icon="['fas', 'check']"/>
        </template>
      </BaseButton>
    </template>
  </Modal>

  <Modal v-show="displayModalStateGenerator === true">

    <template v-slot:modalIcon>
      <font-awesome-icon :icon="['fas', 'file-excel']"/>
    </template>
    <template v-slot:modalTitle>Générer l'état du pointage</template>

    <template v-slot:modalContent>
      <div style="padding:15px; margin:15px;">
        <BaseDatepicker
            v-model="stateGenerator.startDate"
            label="Date de début"
            name="stateGeneratorStartDate"
            :required="true"
            :error="stateGeneratorFormErrors?.stateGeneratorStartDate?.error?.message"
            @onChange="onModalStateGeneratorFormInputChange"
            :displayError="displayError"
        />
        <BaseDatepicker
            v-model="stateGenerator.endDate"
            label="Date de fin"
            name="stateGeneratorEndDate"
            :required="true"
            :error="stateGeneratorFormErrors?.stateGeneratorEndDate?.error?.message"
            @onChange="onModalStateGeneratorFormInputChange"
            :displayError="displayError"
        />
        <BaseSelect
            v-model="stateGenerator.users"
            label="Utilisateurs"
            :defaultOptions="stateGeneratorSelectableUsers"
            name="stateGeneratorUsers"
            :error="stateGeneratorFormErrors?.stateGeneratorUsers?.error?.message"
            @onChange="onModalStateGeneratorFormInputChange"
            :displayError="displayError"
            :multiple="true"
            :allowEmpty="false"
            :optionSelectAll="true"
        />

        <div style="margin:30px 15px 20px 15px">
          <BaseCheckbox
              v-model="stateGenerator.includeDayLines"
              label="Inclure les lignes journalières sur l'état"
          />
        </div>
      </div>
    </template>
    <template v-slot:modalButtons>
      <BaseButton class="white-button" @click="closeModalStateGenerator" buttonText="Fermer">
        <template v-slot:iconBefore>
          <font-awesome-icon :icon="['fas', 'times']"/>
        </template>
      </BaseButton>
      <BaseButton class="orange-button" @click="submitModalStateGenerator" buttonText="Génerer le fichier">
        <template v-slot:iconBefore>
          <font-awesome-icon :icon="['fas', 'file-excel']"/>
        </template>
      </BaseButton>
    </template>
  </Modal>
  <Dialog ref="dialog"/>
</template>

<script>
import {parse, addHours, isSameDay, isAfter, format} from "date-fns"
import ButtonTopbar from '../../components/Topbar/ButtonTopbar'
import BaseRadioButton from '../../components/Base/BaseRadioButton'
import BaseButton from '../../components/Base/BaseButton'
import BaseInput from '../../components/Base/BaseInput'
import BaseCheckbox from '../../components/Base/BaseCheckbox'
import BaseSelect from '../../components/Base/BaseSelect'
import BaseDatepicker from '../../components/Base/BaseDatepicker'
import Aggrid from '../../components/Aggrid/Aggrid'
import Modal from '../../components/App/Modal'
import ModalStep from '../../components/App/ModalStep'
import ModalStepItem from '../../components/App/ModalStepItem'
import Dialog from '../../components/App/Dialog'
import ListClockingTasksActionButtonsRenderer from './ListClockingTasksActionButtonsRenderer'
import ListAffairsActionButtonsRenderer from './ListAffairsActionButtonsRenderer'
import axios from 'axios'
import changeTypeEnum from '../../enums/changeTypeEnum'
import accountRoleEnum from "../../enums/accountRoleEnum";
import store from "@/store";

export default {
  name: 'ClockingTasks',
  components: {
    ButtonTopbar,
    BaseRadioButton,
    BaseButton,
    BaseInput,
    BaseCheckbox,
    BaseSelect,
    BaseDatepicker,
    Aggrid,
    Modal,
    ModalStep,
    ModalStepItem,
    Dialog,
    ListClockingTasksActionButtonsRenderer,
    ListAffairsActionButtonsRenderer
  },
  data() {
    return {
      accountRoleEnum,
      columnDefsTasks: [],
      columnDefsAffairs: [
        {field: 'affairNumber', headerName: `Affaire`, flex: 1, lockPosition: true, suppressMenu: true},
        {field: 'shipToCustomer.name', headerName: `Client`, flex: 1, lockPosition: true, suppressMenu: true},
        {
          field: 'spindle.spindleType.spindleBrand.name',
          headerName: `Marque`,
          flex: 1,
          lockPosition: true,
          suppressMenu: true
        },
        {field: 'spindle.spindleType.name', headerName: `Type`, flex: 1, lockPosition: true, suppressMenu: true},
        {field: 'affairDuration', headerName: `Temps total`, flex: 2, lockPosition: true, suppressMenu: true},
        {field: 'label', headerName: `Label`, flex: 2, lockPosition: true, suppressMenu: true},
        {headerName: '', flex: 1, cellRenderer: 'ListAffairsActionButtonsRenderer', suppressMenu: true},
      ],
      columnDefsPreSearch: [
        {field: 'affair.affairNumber', headerName: `Affaire`, flex: 1, lockPosition: true, suppressMenu: true},
        {field: 'discipline.description', headerName: `Discipline`, flex: 1, lockPosition: true, suppressMenu: true},
        {field: 'comment', headerName: `Commentaire`, flex: 2, lockPosition: true, suppressMenu: true},
        {field: 'taskDuration', headerName: `Durée`, flex: 1, lockPosition: true, suppressMenu: true}
      ],
      frameworkComponentsTasks: {
        ListClockingTasksActionButtonsRenderer: ListClockingTasksActionButtonsRenderer,
      },
      frameworkComponentsAffairs: {
        ListAffairsActionButtonsRenderer: ListAffairsActionButtonsRenderer
      },
      frameworkComponentsPreSearch: {
      },
      displayModalCreateOrEditClockingTask: false,
      displayModalDeleteClockingTask: false,
      displayModalConsultAffairDetail: false,
      createOrEditMode: 'create',
      context: null,
      technicianSearch: null,
      affairSearch: null,
      spindleBrandSearch: null,
      spindleTypeSearch: null,
      dateSearch: null,
      othersDisciplinesAll: [],
      othersDisciplines: [],
      affairDisciplines: [],
      filterTasks: {
        deleted: false,
        spindleBrandId: null,
        spindleTypeId: null,
        affairId: null,
        dateSearch: null,
        onlyFinishedLessThanDays: 3
      },
      filterAffairs: {
        deleted: false,
        spindleBrandId: null,
        spindleTypeId: null,
        affairId: null,
        atLeastOneClockingTask: true,
        onlyAffairsInProgress: true,
        clockingTechnicianId: null,
        noAffairLight: true
      },
      contextPreSearch: null,
      filterPreSearch: {
        accountId : null,
        affairId: null,
        dateSearch: null
      },
      task: {
        account: null,
        affair: null,
        startDate: null,
        endDate: null,
        comment: "",
        discipline: null
      },
      affair: {
        name: null,
        duration: null,
        spindleBrandName: null,
        spindleBrandType: null,
        tasks: null
      },
      formErrors: {},
      displayError: false,
      displayModalStateGenerator: false,
      stateGenerator: {
        startDate: null,
        endDate: null,
        users: [],
        includeDayLines: true
      },
      stateGeneratorSelectableUsers: [],
      stateGeneratorFormErrors: {},
      clockingTaskFromAffairDetailFilter: null

    }
  },
  beforeMount() {
    this.context = {
      componentParent: this
    };

    this.contextAffair = {
      componentParent: this
    };

    this.contextPreSearch = {
      componentParent: this
    };

    this.resetTask();
  },
  mounted() {
    this.loadDisciplines();

    this.loadActiveTechnicians();

    this.columnDefsTasks = [];

    if (!this.$store.getters.isTechnician) {
      this.columnDefsTasks.push({
        field: 'account.name',
        headerName: `Agent`,
        flex: 1,
        lockPosition: true,
        suppressMenu: true
      });
    } else {
      this.filterAffairs.clockingTechnicianId = this.$store.state.authentication.user.id;
    }

    this.columnDefsTasks.push(
        {field: 'affair.affairNumber', headerName: `Affaire`, flex: 1, lockPosition: true, suppressMenu: true},
        {field: 'discipline.description', headerName: `Discipline`, flex: 1, lockPosition: true, suppressMenu: true},
        {field: 'comment', headerName: `Commentaire`, flex: 2, lockPosition: true, suppressMenu: true},
        {field: 'startDate', headerName: `Date de début`, flex: 1, lockPosition: true, suppressMenu: true},
        {field: 'endDate', headerName: `Date de fin`, flex: 1, lockPosition: true, suppressMenu: true},
        {field: 'taskDuration', headerName: `Durée`, flex: 1, lockPosition: true, suppressMenu: true},
        {
          headerName: '',
          flex: 1,
          cellRenderer: 'ListClockingTasksActionButtonsRenderer',
          suppressMenu: true,
          cellRendererParams: {isTechnician: this.$store.getters.isTechnician}
        }
    );

    if (this.$route.query?.action === 'addClockingTask') {
      // On récupère l'affaire
      axios.get(`affair/search?searchedText=${this.$route.query.affairId}&affairNotDeleted=true`, {
        showPreloader: true
      })
          .then((response) => {
            this.task.affair = {
              label: response.data[0].name,
              value: response.data[0].id,
              data: response.data[0]
            }

            this.task.discipline = this.$route.query.disciplineId;
          })
          .catch(() => {
          });

      this.displayCreateTaskModal();
    }
  },
  methods: {
    loadDisciplines() {
      axios.get('discipline/search', {
        showPreloader: true
      })
          .then((response) => {
            this.othersDisciplinesAll = response.data;
            this.othersDisciplines = response.data;
          })
          .catch(() => {
          });
    },
    loadActiveTechnicians() {
        let stateGeneratorSelectableUsers = [];
        if (store.getters.isAdmin || store.getters.isWorkshopManager || store.getters.isTechnicianManager) {
            Promise.all([
            axios.get('account/search?role=technician', {showPreloader: true}),
            axios.get('account/search?role=workshopManager', {showPreloader: true}),
            axios.get('account/search?role=technicianManager', {showPreloader: true}),
            ]).then((response) => {
            let stateGeneratorSelectableUsers = [];
            response[0].data.map(user => {
                stateGeneratorSelectableUsers.push({label: user.name, value: `${user.id}`, id: user.id});
            });

          response[1].data.map(user => {
            stateGeneratorSelectableUsers.push({label: user.name, value: `${user.id}`, id: user.id});
          });

            response[2].data.map(user => {
                stateGeneratorSelectableUsers.push({label: user.name, value: `${user.id}`, id: user.id});
            });

            stateGeneratorSelectableUsers.sort((u1, u2) => {
              if(u1.label !== null && u2.label !== null) return u1.label.localeCompare(u2.label);
              return u1.id - u2.id;
            });

            this.stateGeneratorSelectableUsers = stateGeneratorSelectableUsers;
            }).catch(() => {});
        } else if (store.getters.isTechnician) {
            this.stateGeneratorSelectableUsers = [{
                label: store.state.authentication.user.firstname + ' ' + store.state.authentication.user.lastname,
                value: `${store.state.authentication.user.id}`,
                id: store.state.authentication.user.id
            }];
        }

    },
    reloadAffairDisciplines() {
      if (this.task?.affair !== null) {
        axios.get(`discipline/search?affairId=${this.task.affair.value}`, {
          showPreloader: true
        })
            .then((response) => {
              this.affairDisciplines = response.data;

              this.othersDisciplines = this.othersDisciplinesAll.filter((otherDiscipline) => {
                return this.affairDisciplines.findIndex(affairDiscipline => affairDiscipline.id === otherDiscipline.id) === -1;
              });
            })
            .catch(() => {
            });
      } else {
        this.affairDisciplines = [];
      }
    },
    onFormInputChange(input) {
      if (input.error?.message !== null) {
        this.formErrors[input.name] = input;
      } else {
        delete this.formErrors[input.name];
      }

      switch (input.name) {
        case 'taskStartDate':
        case 'taskEndDate':

          // Si on est en édition et qu'on a pas d'erreur de type validator
          if (this.createOrEditMode === 'edit' && (!this.formErrors.taskEndDate || this.formErrors.taskEndDate.error.type === 'custom')) {
            let taskStartDate = input.name === 'taskStartDate' ? input.value : this.task.startDate;
            let taskEndDate = input.name === 'taskEndDate' ? input.value : this.task.endDate;

            // Si on a la date de début et la date de fin et qu'elles ne sont pas sur le même jour, on génère une erreur
            if (taskStartDate !== null && taskEndDate !== null && typeof taskStartDate === 'string' && typeof taskEndDate === 'string') {
              taskStartDate = parse(taskStartDate, 'dd/MM/yyyy HH:mm', new Date());
              taskEndDate = parse(taskEndDate, 'dd/MM/yyyy HH:mm', new Date());

              let message = null;

              if (!isSameDay(taskStartDate, taskEndDate)) {
                message = 'La date de fin doit être au même jour que la date de début';
              } else if (isAfter(taskStartDate, taskEndDate)) {
                message = 'La date de fin doit être postérieure à la date de début';
              }

              if (message !== null) {
                this.formErrors['taskEndDate'] = {
                  name: 'taskEndDate',
                  value: taskEndDate,
                  error: {
                    type: 'custom',
                    message: message
                  }
                }
              }
            }
          }
          break;
        case 'taskAffair': {
          this.reloadAffairDisciplines()
        }
      }
    },
    onModalStateGeneratorFormInputChange(input) {
      if (input.error?.message !== null) {
        this.stateGeneratorFormErrors[input.name] = input;
      } else {
        delete this.stateGeneratorFormErrors[input.name];
      }

      //Ajouter Cas complémentaires
      let stateGeneratorStartDate = this.stateGenerator.startDate;
      let stateGeneratorEndDate = this.stateGenerator.endDate;

      //Switch input.name

      let taskStartDate = input.name === 'taskStartDate' ? input.value : this.task.startDate;
      let taskEndDate = input.name === 'taskEndDate' ? input.value : this.task.endDate;

      // Si on a la date de début et la date de fin et qu'elles ne sont pas sur le même jour, on génère une erreur
      if (stateGeneratorStartDate !== null && stateGeneratorEndDate !== null && typeof stateGeneratorStartDate === 'string' && typeof stateGeneratorEndDate === 'string') {
        stateGeneratorStartDate = parse(stateGeneratorStartDate, 'dd/MM/yyyy', new Date());
        stateGeneratorEndDate = parse(stateGeneratorEndDate, 'dd/MM/yyyy', new Date());

        let message = null;

        if (isAfter(stateGeneratorStartDate, stateGeneratorEndDate)) {
          message = 'La date de fin doit être postérieure à la date de début';
        }

        if (message !== null) {
          this.stateGeneratorFormErrors['stateGeneratorEndDate'] = {
            name: 'stateGeneratorEndDate',
            value: stateGeneratorEndDate,
            error: {
              type: 'custom',
              message: message
            }
          }
        } else {
          delete this.stateGeneratorFormErrors['stateGeneratorEndDate'];
        }
      }

      if (this.stateGenerator.users.length === 0) {
        this.stateGeneratorFormErrors['stateGeneratorUsers'] = {
          name: 'stateGeneratorUsers',
          value: this.stateGenerator.users,
          error: {
            type: 'custom',
            message: 'Dous devez sélectionner au moins un agent'
          }
        }
      } else {
        delete this.stateGeneratorFormErrors['stateGeneratorUsers'];
      }


    },
    onFiltersChange(input) {
      if (input.changeType === changeTypeEnum.USER) {
        switch (input.name) {
          case 'accountId':
            this.filterAffairs['clockingTechnicianId'] = input?.value?.value || null;
            this.filterTasks[input.name] = input?.value?.value || null;
            this.filterPreSearch[input.name] = input?.value?.value || null;
            break;
          case 'affairId':
            this.spindleBrandSearch = null;
            this.spindleTypeSearch = null;
            this.filterAffairs[input.name] = input?.value?.value || null;
            this.filterTasks[input.name] = input?.value?.value || null;
            this.filterPreSearch[input.name] = input?.value?.value || null;
            break;
          case 'spindleBrandId':
            this.affairSearch = null;
            this.spindleTypeSearch = null;
            this.filterAffairs[input.name] = input?.value?.value || null;
            this.filterTasks[input.name] = input?.value?.value || null;
            break;
          case 'spindleTypeId':
            this.affairSearch = null;
            this.spindleBrandSearch = null;
            this.filterAffairs[input.name] = input?.value?.value || null;
            this.filterTasks[input.name] = input?.value?.value || null;
            break;
          case 'dateSearch':
            this.filterTasks[input.name] = input?.value || null;
            this.filterPreSearch[input.name] = input?.value || null;
            break;
        }
      }
    },
    displayCreateTaskModal() {
      this.createOrEditMode = 'create';
      this.task.startDate = format(new Date(), 'dd/MM/yyyy HH:mm');
      this.displayModalCreateOrEditClockingTask = true;
    },
    displayStateGeneratorModal() {
      this.stateGenerator = {
        ...this.stateGenerator,
        startDate: null,
        endDate: null,
        users: []
      };
      this.displayModalStateGenerator = true;
    },
    closeModalStateGenerator() {
      this.displayModalStateGenerator = false;
    },
    checkModalStateGenerator() {
      this.displayError = true;

      return (Object.keys(this.stateGeneratorFormErrors).length === 0);

    },
    submitModalStateGenerator() {
      let me = this;
      if (!this.checkModalStateGenerator()) return;
      const usersParam = this.stateGenerator.users.reduce((usersParam, user) => `${usersParam}&users=${user.id}`, "");

      axios
          .get(`clockingTask/generateStateFile?startDate=${this.stateGenerator.startDate}&endDate=${this.stateGenerator.endDate}${usersParam}`, {
            toastError: true,
            showPreloader: true
          })
          .then((result) => {

            let rows = [];
            for (let stat of result.data) {
              const startDate = stat.startDate ? new Date(stat.startDate) : null;
              const endDate = stat.startDate ? new Date(stat.endDate) : null;

              let taskDurationForReport = '???';
              if (startDate != null && endDate != null) {
                let taskDuration = (endDate.getTime() - startDate.getTime()) / 60000;

                let taskDurationHours = Math.floor(taskDuration / 60);
                let taskDurationMinutes = Math.floor((taskDuration % 60) / 0.6) / 100;
                taskDurationForReport = Math.round((taskDurationHours + taskDurationMinutes) * 100) / 100;
              }


              rows.push([
                stat.account ? `${stat.account.firstname} ${stat.account.lastname}` : '???',
                stat.affair?.affairNumber || '???',
                stat.affair?.label || '???',
                stat.affair?.shipToCustomer?.name,
                `${stat.affair?.spindle?.spindleType?.spindleBrand?.name || '???'} - ${stat.affair?.spindle?.spindleType?.name || '???'} - ${stat.affair?.spindle?.serialNumber || '???'} - ${stat.affair?.spindle?.serialNumber || '???'}`,
                stat.discipline?.description || '???',
                            startDate || '???',
                            startDate || '???',
                            endDate || '???',
                taskDurationForReport,
                stat.comment
              ]);
            }

            rows.sort((ra, rb) => {

              let nameCompareResult = 0;
              let dateCompareResult = 0;

              if (ra[0] === '???' && rb[0] === '???') nameCompareResult = 0;
              else if (ra[0] !== '???' && rb[0] === '???') nameCompareResult = 1;
              else if (ra[0] === '???' && rb[0] !== '???') nameCompareResult = -1;
              else nameCompareResult = ra[0].localeCompare(rb[0]);

              if (ra[6] === '???' && rb[6] === '???') dateCompareResult = 0;
              else if (ra[6] !== '???' && rb[6] === '???') dateCompareResult = 1;
              else if (ra[6] === '???' && rb[6] !== '???') dateCompareResult = -1;
              else {
                dateCompareResult = ra[6].getTime() - rb[6].getTime();
              }

              return nameCompareResult || dateCompareResult;
            });

            rows.forEach((row, index) => {
                if (row[6] !== '???') {
                rows[index][6] = format(new Date(row[6]), 'dd/MM/yyyy');
                }
                if(row[7] !== '???'){
                    rows[index][7] = format(new Date(row[7]), 'HH:mm');
                }
                if(row[8] !== '???'){
                    rows[index][8] = format(new Date(row[8]), 'HH:mm');
                }
            });


            let rowsForCsv = [];

            //Calcul des lignes de totaux par jour (si voulues)
            if (me.stateGenerator.includeDayLines) {

                const daySubTotalRows = [];
                rows.forEach((row, _index, _arr) => {
                    if(!daySubTotalRows.some(r => r.agentName === row[0] && r.date === row[6])){
                        daySubTotalRows.push({agentName: row[0], date: row[6], totalTime : 0.00});
                    }
                    const totIndex = daySubTotalRows.findIndex(r => r.agentName === row[0] && r.date === row[6]);

                    daySubTotalRows[totIndex].totalTime = daySubTotalRows[totIndex].totalTime + (Math.round(parseFloat(row[9]) * 100) / 100)
                    
                });

                daySubTotalRows.forEach((daySubTotalRow, _1, _2) => {
                    rowsForCsv.push([
                        daySubTotalRow.agentName, '', '', '', '', '', daySubTotalRow.date, '', '', daySubTotalRow.totalTime, ''
                    ]);

                    rows
                        .filter(r => r[0] === daySubTotalRow.agentName && r[6] === daySubTotalRow.date)
                        .forEach((row) => {
                            rowsForCsv.push(row);
                        });
                });
            }
            else {
                rowsForCsv = [...rows] 
            }

            rowsForCsv = rowsForCsv.reduce((oldRowsForCsv, row) => {
              oldRowsForCsv.push(
                row.reduce((rowCells, cell, cellIndex) => {
                  let newCell = cellIndex === 9 ? cell.toString() : cell ;
                  newCell = newCell.replaceAll('\r\n','');
                  newCell = newCell.replaceAll('\n','');
                  rowCells.push(newCell);
                  return rowCells
                }, [])
              )
              return oldRowsForCsv;
            }, []);


            //rowsForCsv = rowsForCsv.map((e){return e.map((cell, cellIndex){return cellIndex === 9 ? cell.toString().replace(".", ",") : cell})});

            //On rajoute la ligne des headers
            rowsForCsv.splice(0, 0, [
              'Technicien',
              'Numéro affaire',
              'Label de l\'affaire',
              'Client',
              'Marque Type Numéro de serie BROCHE',
              'Discipline',
              'Date du pointage',
                        'Heure début de pointage',
                        'Heure fin de pointage',
              'Temps pointé',
              'Commentaire'
            ]);


            let csvContent = rowsForCsv.map(e => e.join(";")).join("\n");

            this.exportCSV(csvContent, 'export_planning_state_');

            this.closeModalStateGenerator();
          })
          .catch(() => {
            console.log("submitModalStateGenerator -> erreur");
          });

    },
    displayEditClockingTaskModal(task) {
      this.createOrEditMode = 'edit';
      this.prepareTask(task)
      this.displayModalCreateOrEditClockingTask = true;
    },
    displayConsultAffairDetailModal(affair) {
      this.prepareAffair(affair);
      this.displayModalConsultAffairDetail = true;
    },
    closeModalClockingTask() {
      this.displayModalCreateOrEditClockingTask = false
      this.resetTask();
      this.displayError = false;
    },
    closeModalAffairDetail() {
      this.clockingTaskFromAffairDetailFilter = null;
      this.displayModalConsultAffairDetail = false;
    },
    submitModalClockingTask() {
      if (this.createOrEditMode === 'create') {
        this.createTask();
      } else {
        this.editTask();
      }
    },
    createTask() {
      if (!this.checkForm()) return;

      axios
          .post('clockingTask/create', this.formateTask(this.task), {
            toastSuccessMessage: `Tâche créée`,
            toastError: true,
            showPreloader: true
          })
          .then(() => {
            this.closeModalClockingTask();
            this.emitter.emit('ag-grid-reload')
            this.resetTask()
          })
          .catch(() => {
          });
    },
    editTask() {
      if (!this.checkForm()) return;

      axios
          .put('clockingTask/update', this.formateTask(this.task), {
            toastSuccessMessage: `Tâche modifiée`,
            toastError: true,
            showPreloader: true
          })
          .then(() => {
            this.closeModalClockingTask();
            this.emitter.emit('ag-grid-reload')
            this.resetTask()
          })
          .catch(() => {
          });
    },
    checkForm() {
      this.displayError = true;

      let taskStartDate = parse(this.task.startDate, "dd/MM/yyyy HH:mm", new Date());
      let taskEndDate = parse(this.task.endDate, "dd/MM/yyyy HH:mm", new Date());
      
      if (this.task.endDate !== null && isAfter(taskStartDate, taskEndDate)) {
        this.$refs.dialog.show({
          type: 'alert',
          title: 'Dates invalides',
          message: `La date de fin ne peut pas précéder la date de début`
        }).then(() => {})
            .catch(() => {});
        return false;
      }

      if (this.createOrEditMode === 'create') {
        delete this.formErrors['taskEndDate'];
      }

      if (Object.keys(this.formErrors).length === 0) {
        if (this.task.discipline === null) {
          this.$refs.dialog.show({
            type: 'alert',
            title: 'Information manquante',
            message: `Merci de sélectionner une discipline`
          }).then(() => {})
              .catch(() => {});

          return false;
        } else {
          return true;
        }
      } else {
        return false
      }
    },
    prepareTask(task) {
      this.task = JSON.parse(JSON.stringify(task));
      this.task.account = {data: {id: this.task.account.id}, value: this.task.account.id, label: this.task.account.name}
      this.task.affair = {data: {id: this.task.affair.id}, value: this.task.affair.id, label: this.task.affair.name}
      this.task.startDate = parse(task.startDate, "dd/MM/yyyy HH:mm", new Date());
      if (task.endDate) {
        this.task.endDate = task.endDate;
      } else {
        this.task.endDate = parse(task.startDate, "dd/MM/yyyy HH:mm", new Date());
        this.task.endDate.setHours(new Date().getHours(), new Date().getMinutes(), 0, 0)
      }
      this.task.discipline = this.task.discipline.id;
    },
    prepareAffair(affair) {
      this.affair.name = affair.affairNumber + ' - ' + affair.shipToCustomer.name;
      this.affair.duration = affair.affairDuration;
      this.affair.spindleBrandType = affair.spindle?.spindleType.name;
      this.affair.spindleBrandName = affair.spindle?.spindleType.spindleBrand.name;
      this.affair.tasks = affair.affairTasks;
    },
    formateTask(task) {
      task = JSON.parse(JSON.stringify(task));
      task.accountId = this.$store.getters.isTechnician ? this.$store.state.authentication.user.id : parseInt(task.account.data.id);
      task.disciplineId = parseInt(task.discipline);
      task.affairId = parseInt(task.affair.data.id);
      return task;
    },
    resetTask() {
      this.task = {
        account: null,
        affair: null,
        startDate: null,
        endDate: null,
        comment: "",
        discipline: null,
        isClosed: null
      };
    },
    onDisplayOldAffairsFilterChange(displayOldAffairs) {
      this.filterAffairs.onlyAffairsInProgress = displayOldAffairs;
    },
    editClockingTaskFromAffairDetail(event) {
      let currentTask = this.affair.tasks[event.currentTarget.dataset.index];

      // On charge la tâche à modifier
      this.task.id = currentTask.id;
      this.task.account = {
        data: {id: currentTask.account.id},
        value: currentTask.account.id,
        label: currentTask.account.name
      }
      this.task.affair = {
        data: {id: currentTask.affair.id},
        value: currentTask.affair.id,
        label: currentTask.affair.name
      }
      this.task.startDate = currentTask.startedDate;
      this.task.endDate = currentTask.endedDate;
      this.task.discipline = currentTask.discipline.id;
      this.task.comment = currentTask.comment;

      this.createOrEditMode = 'edit';
      this.displayModalCreateOrEditClockingTask = true;
    },
    deleteClockingTaskFromAffairDetail(event) {

      let currentTask = this.affair.tasks[event.currentTarget.dataset.index];

      this.$refs.dialog.show({
        type: 'confirm',
        title: 'Confirmation',
        message: `Êtes-vous sûr de vouloir supprimer ce pointage ?`
      }).then(() => {

        console.log(`Delete task with id : ${currentTask.id}`);

        axios
          .delete(`clockingTask/${currentTask.id}`, {
            toastSuccessMessage: `Pointage supprimé`,
            toastError: true,
            showPreloader: true
          })
          .then(() => {
            this.closeModalAffairDetail();
            this.emitter.emit('ag-grid-reload');
            this.resetTask();

          })
          .catch(() => {
          });
      })
      .catch(() => {
      });
    },

    changeClockingTaskFromAffairDetailFilter(columnIndex){

      if (this.clockingTaskFromAffairDetailFilter && ((this.clockingTaskFromAffairDetailFilter?.columnIndex === columnIndex) && (this.clockingTaskFromAffairDetailFilter.sorting === 'asc'))) {
        this.clockingTaskFromAffairDetailFilter = {
          ...this.clockingTaskFromAffairDetailFilter,
          sorting: 'desc'
        }
      } else if (this.clockingTaskFromAffairDetailFilter && ((this.clockingTaskFromAffairDetailFilter?.columnIndex === columnIndex) && (this.clockingTaskFromAffairDetailFilter.sorting === 'desc'))) {
        this.clockingTaskFromAffairDetailFilter = null;
      } else {
        this.clockingTaskFromAffairDetailFilter = {
          columnIndex: columnIndex,
          sorting: 'asc'
        }
      }

      if (this.clockingTaskFromAffairDetailFilter !== null) {
        switch (this.clockingTaskFromAffairDetailFilter.columnIndex) {
          case 2: {
            this.affair.tasks.sort((ta, tb) => {
              return ta.startedDate.localeCompare(tb.startedDate) * (this.clockingTaskFromAffairDetailFilter.sorting === 'asc' ? 1 : -1);
            })
            break;
          }
          case 3: {
            this.affair.tasks.sort((ta, tb) => {
              return ta.endedDate.localeCompare(tb.endedDate) * (this.clockingTaskFromAffairDetailFilter.sorting === 'asc' ? 1 : -1);
            })
            break;
          }
          case 4: {
            this.affair.tasks.sort((ta, tb) => {
                let taDurationInMinutes = ta.duration.split('H').reduce((acc, time) => (60 * acc) + +time);
                let tbDurationInMinutes = tb.duration.split('H').reduce((acc, time) => (60 * acc) + +time) * (this.clockingTaskFromAffairDetailFilter.sorting === 'asc' ? 1 : -1);

                if (taDurationInMinutes < tbDurationInMinutes) {
                    return 1;
                } else if (taDurationInMinutes > tbDurationInMinutes) {
                    return -1;
                } else {
                    return 0;
                }
            })
            break;
          }
        }
      }

    }
  }
}
</script>