<template>
    <div class="container-action-button" v-if="params.data">
        <BaseButton class="rectangle-button" @click="onEditClockingTask" buttonText="Terminer" v-if="params.data.endDate == null">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'check']" />
            </template>
        </BaseButton>
        <BaseButton class="rectangle-button" @click="onEditClockingTask" buttonText="Modifier" v-if="!params.isTechnician && params.data.endDate != null">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'pen']" />
            </template>
        </BaseButton>
    </div>
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton.vue';
    import * as Vue from "vue";
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    export default {
        name: 'ListClockingTasksActionButtonsRenderer',
        components: {
            BaseButton: Vue.shallowRef(BaseButton),
            FontAwesomeIcon: Vue.shallowRef(FontAwesomeIcon)
        },
        methods: {
            onEditClockingTask() {
                this.params.context.componentParent.displayEditClockingTaskModal(this.params.data);
            }
        }
    }        
</script>